import { motion } from 'framer-motion'

import ReconditBottomBackgroundMap from '@/pages/form/assets/img/recondit-bottom-bg-map.png'
import ReconditLogo from '@/pages/form/assets/img/recondit-logo-with-letters.png'
import ReconditTopBackgroundMap from '@/pages/form/assets/img/recondit-top-bg-map.png'
import { type EventFormOnboardingProps } from '@/pages/form/types.ts'

export const FormOnboarding: React.FC<EventFormOnboardingProps> = ({ onStartForm }) => (
    <>
        <motion.div
            initial={{ opacity: 0, y: -70 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className='absolute left-0 top-0 z-0 w-full md:top-[-10%] lg:top-[-20%]'
        >
            <img src={ReconditTopBackgroundMap} alt='Mapa de fons' className='w-full' />
        </motion.div>

        <div className='relative z-10 flex flex-col items-center justify-start'>
            <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
                className='m-10 mb-5 max-w-md'
            >
                <img src={ReconditLogo} alt='Recòndit Festival' className='mx-auto w-4/5 md:w-3/5' />
            </motion.div>

            <div className='z-10 mx-4 mb-10 w-full max-w-lg text-center'>
                <motion.h1
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.5, duration: 0.5 }}
                    className='mb-6 font-recondit-poppins text-2xl font-normal tracking-wide text-secondary-foreground md:text-4xl'
                >
                    ENS AJUDES A FER CRÉIXER EL RECÒNDIT?
                </motion.h1>

                <motion.p
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.9, duration: 0.5 }}
                    className='text-lg text-primary-foreground md:text-xl'
                >
                    Comptem amb tu!
                </motion.p>
            </div>

            <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 1.2, duration: 0.5 }}
                className='mb-8 flex flex-col items-center gap-6'
            >
                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={onStartForm}
                    className='mt-4 transform bg-secondary-foreground px-10 py-4 font-recondit-poppins text-lg text-background transition duration-300 ease-in-out hover:bg-secondary-foreground/90'
                >
                    COMENÇAR
                </motion.button>
            </motion.div>
        </div>

        <motion.div
            initial={{ opacity: 0, y: 70 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className='absolute bottom-0 left-0 z-0 w-full md:bottom-[-5%] lg:bottom-[-10%]'
        >
            <img src={ReconditBottomBackgroundMap} alt='Mapa de fons' className='w-5/6 md:w-2/3' />
        </motion.div>
    </>
)
