import { Summaries } from '@monorepo/functions/src/types/event'
import {
    Category,
    Department,
    LikertFeedback,
    MultiSelectFeedback,
    OptionsSliderFeedback,
    RadioFeedback,
    TextFeedback,
    YesNoFeedback,
} from '@monorepo/functions/src/types/feedback'

interface GenerateMarkdownReportParams {
    likertFeedbacks: LikertFeedback[]
    yesNoFeedbacks: YesNoFeedback[]
    optionsSliderFeedback: OptionsSliderFeedback[]
    multiSelectFeedback: MultiSelectFeedback[]
    radioFeedback: RadioFeedback[]
    textFeedbacks: TextFeedback[]
    summaries?: Summaries
}

const getAverageScore = (feedbacks: LikertFeedback[], question: string) => {
    const filteredFeedbacks = feedbacks.filter(f => f.question.title === question)
    const sum = filteredFeedbacks.reduce((a, b) => a + b.answer.value, 0)
    const avg = sum / filteredFeedbacks.length
    return Math.round(avg * 100) / 100
}

const getYesNoStats = (question: string, feedbacks: YesNoFeedback[]) => {
    const answers = feedbacks.filter(q => q.question.title === question).map(r => r.answer)
    const total = answers.length
    const yesCount = answers.filter(a => a.value).length
    const noCount = answers.filter(a => !a.value).length
    return {
        yesCount,
        noCount,
        total,
        yesPercentage: total > 0 ? Math.round((yesCount / total) * 100) : 0,
        noPercentage: total > 0 ? Math.round((noCount / total) * 100) : 0,
    }
}

const getSingleSelectStats = (question: string, feedbacks: (RadioFeedback | OptionsSliderFeedback)[]) => {
    const answers = feedbacks.filter(q => q.question.title === question).map(r => r.answer)
    const possibleAnswers = feedbacks[0]?.question.options || []
    const total = answers.length

    return {
        total,
        stats: possibleAnswers.map(answer => ({
            label: answer.label,
            count: answers.filter(a => a.value === answer.value).length,
            percentage:
                total > 0 ? Math.round((answers.filter(a => a.value === answer.value).length / total) * 100) : 0,
        })),
    }
}

const getMultiSelectStats = (question: string, feedbacks: MultiSelectFeedback[]) => {
    const answers = feedbacks.filter(q => q.question.title === question).map(r => r.answer)
    const possibleAnswers = feedbacks[0]?.question.options || []
    const total = answers.length

    return {
        total,
        stats: possibleAnswers.map(answer => ({
            label: answer.label,
            count: answers.filter(a => a.value.includes(answer.value)).length,
            percentage:
                total > 0 ? Math.round((answers.filter(a => a.value.includes(answer.value)).length / total) * 100) : 0,
        })),
    }
}

const getDepartmentCount = (category: Category, department: Department, textFeedbacks: TextFeedback[]) => {
    return textFeedbacks.filter(
        comment => comment.answer.categories.includes(category) && comment.answer.departments.includes(department),
    ).length
}

export const generateMarkdownReport = ({
    likertFeedbacks,
    yesNoFeedbacks,
    optionsSliderFeedback,
    multiSelectFeedback,
    radioFeedback,
    textFeedbacks,
    summaries,
}: GenerateMarkdownReportParams) => {
    let markdown = '# Reporte de Feedback\n\n'

    // Likert Scale Questions
    const likertQuestions = [...new Set(likertFeedbacks.map(f => f.question.title))]
    if (likertQuestions.length > 0) {
        markdown += '## Preguntas de Escala Likert\n\n'
        likertQuestions.forEach(question => {
            const feedbacks = likertFeedbacks.filter(f => f.question.title === question)
            const avg = getAverageScore(likertFeedbacks, question)
            markdown += `### ${question}\n`
            markdown += `- Promedio: ${avg.toFixed(2)} de 5 (${feedbacks.length} respuestas)\n\n`
        })
    }

    // Yes/No Questions
    const yesNoQuestions = [...new Set(yesNoFeedbacks.map(f => f.question.title))]
    if (yesNoQuestions.length > 0) {
        markdown += '## Preguntas de Sí/No\n\n'
        yesNoQuestions.forEach(question => {
            const stats = getYesNoStats(question, yesNoFeedbacks)
            markdown += `### ${question}\n`
            markdown += `- Total de respuestas: ${stats.total}\n`
            markdown += `- Sí: ${stats.yesCount} (${stats.yesPercentage}%)\n`
            markdown += `- No: ${stats.noCount} (${stats.noPercentage}%)\n\n`
        })
    }

    // Single Select Questions (Radio + Options Slider)
    const singleSelectQuestions = [
        ...new Set([...radioFeedback.map(f => f.question.title), ...optionsSliderFeedback.map(f => f.question.title)]),
    ]
    if (singleSelectQuestions.length > 0) {
        markdown += '## Preguntas de Selección Única\n\n'
        singleSelectQuestions.forEach(question => {
            const feedbacks = [...radioFeedback, ...optionsSliderFeedback].filter(f => f.question.title === question)
            const { total, stats } = getSingleSelectStats(question, feedbacks)
            markdown += `### ${question}\n`
            markdown += `- Total de respuestas: ${total}\n`
            stats.forEach(stat => {
                markdown += `- ${stat.label}: ${stat.count} (${stat.percentage}%)\n`
            })
            markdown += '\n'
        })
    }

    // Multi Select Questions
    const multiSelectQuestions = [...new Set(multiSelectFeedback.map(f => f.question.title))]
    if (multiSelectQuestions.length > 0) {
        markdown += '## Preguntas de Selección Múltiple\n\n'
        multiSelectQuestions.forEach(question => {
            const { total, stats } = getMultiSelectStats(question, multiSelectFeedback)
            markdown += `### ${question}\n`
            markdown += `- Total de respuestas: ${total}\n`
            stats.forEach(stat => {
                markdown += `- ${stat.label}: ${stat.count} (${stat.percentage}%)\n`
            })
            markdown += '\n'
        })
    }

    // Text Feedback Statistics
    if (textFeedbacks.length > 0) {
        markdown += '## Estadísticas de Comentarios\n\n'
        const categories: Category[] = ['Problemas', 'Fortalezas', 'Peticiones', 'Otros']
        const departments: Department[] = ['Música', 'Ambiente', 'Personal', 'Seguridad', 'Servicios', 'Pricing']

        categories.forEach(category => {
            markdown += `### ${category}\n`
            departments.forEach(department => {
                const count = getDepartmentCount(category, department, textFeedbacks)
                if (count > 0) {
                    markdown += `- ${department}: ${count} comentarios\n`
                }
            })

            // Add the summary for this category if available
            if (summaries?.default) {
                const summary = (() => {
                    switch (category) {
                        case 'Problemas':
                            return summaries.default.problems
                        case 'Peticiones':
                            return summaries.default.requests
                        case 'Fortalezas':
                            return summaries.default.strengths
                        case 'Otros':
                            return summaries.default.others
                    }
                })()

                if (summary) {
                    markdown += '\nResumen:\n'
                    markdown += `${summary}\n`
                }
            }
            markdown += '\n'
        })
    }

    // Save the markdown file
    const blob = new Blob([markdown], { type: 'text/markdown' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'reporte-feedback.md'
    a.click()
    window.URL.revokeObjectURL(url)
}
