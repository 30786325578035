import { useNavigate } from 'react-router-dom'

import { signOut } from 'firebase/auth'
import { ArrowLeft, Download, LogOut } from 'lucide-react'

import { useAuth } from '@/shared/auth'
import { auth } from '@/shared/firebase/firebase'
import { useDocument } from '@/shared/firebase/hooks/useDocument'
import { generateMarkdownReport } from '@/shared/lib/generateMarkdownReport'
import { Button } from '@/shared/ui/button'
import { SidebarTrigger } from '@/shared/ui/sidebar'
import { useEventId } from '@/shared/useEventId'
import { Summaries } from '@monorepo/functions/src/types/event'

import { useFeedback } from '../model/FeedbackContext'

export const Header = () => {
    const navigate = useNavigate()
    const eventId = useEventId()

    const { isLoggedIn } = useAuth()
    const {
        likertFeedbacks,
        yesNoFeedbacks,
        optionsSliderFeedback,
        multiSelectFeedback,
        radioFeedback,
        textFeedbacks,
    } = useFeedback()

    const { document: summaries } = useDocument<Summaries>({
        collectionName: `events/${eventId}/summaries`,
        id: 'default',
    })

    const logout = async () => {
        await signOut(auth)
        navigate('../login')
    }

    const handleDownloadReport = () => {
        generateMarkdownReport({
            likertFeedbacks,
            yesNoFeedbacks,
            optionsSliderFeedback,
            multiSelectFeedback,
            radioFeedback,
            textFeedbacks,
            summaries,
        })
    }

    return (
        <div className='sticky top-0 z-50 mb-5 flex items-center justify-between px-6 py-3 shadow-md backdrop-blur-md'>
            <div className='flex items-center gap-4'>
                <img className='w-80' src='/boothbits-logo.svg' alt='BoothBits logo' />
            </div>
            {isLoggedIn && (
                <div className='flex items-center gap-2'>
                    <Button variant='ghost' onClick={handleDownloadReport} className='flex items-center gap-2'>
                        <Download className='h-4 w-4' />
                        Descargar Reporte
                    </Button>
                    <Button variant='ghost' onClick={() => navigate('/')} className='flex items-center gap-2'>
                        <ArrowLeft className='h-4 w-4' />
                        Todos mis eventos
                    </Button>
                    <Button variant='ghost' onClick={logout} className='hidden md:block'>
                        Cerrar sesión
                    </Button>
                    <LogOut className='mr-1 block h-4 w-4 md:hidden' />
                    <SidebarTrigger />
                </div>
            )}
        </div>
    )
}
